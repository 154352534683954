<template>
    <van-popup
        v-model="popupVisible"
        position="bottom"
        class="popup"
        :closeOnClickModal="false"
        popup-transition="popup-fade">
        <div class="dialog">
            <div class="moduleHeader">
                <div class="title">案例详情页展示模块设置</div>
                <div class="sub">自定义案例详情页分享展示模块</div>
                <i class="iconfont icon-xiaohaoshanchu" @click="cancle"></i>
            </div>
            <template  v-for="item in module">
                <div class="moduleHeader other" v-if="item.show">
                    <div class="type">{{item.title}}</div>
                    <div class="sub">{{item.sub}}</div>
                    <mt-switch v-model="item.open" class="switchBtn"></mt-switch>
                </div>
            </template>
            
            <div class="next" @click="next">下一步</div>
            <!-- <a href="tel:13256278829" class="call" @click="cancle">呼叫</a>
            <div class="call copyPhone" @click="copyWord"
            :data-clipboard-text="13242428697">复制</div>
            <div class="cancle" @click.stop="cancle">取消</div> -->
        </div>
    </van-popup>
</template>
<script>
export default {
    name:'popDialog',
    props:{
        popupVisible:{
            type:Boolean,
            default:false,
        },
        shareObj:{
            type:Object,
        }
    },
    data(){
        return{
            module:[
               {
                   id:'1',
                   title:'H5店铺入口',
                   sub:'默认开启，分享出去用户可见H5店铺入口并体验',
                   open:true,
                   show:true,
                   type:'web'
               },
               {
                   id:'2',
                   title:'小程序店铺入口',
                   sub:'默认开启，分享出去用户可见小程序入口并体验',
                   open:true,
                   show:true,
                   type:'micro'
               },
               {
                   id:'3',
                   title:'小程序店铺入口',
                   sub:'默认开启，分享出去用户可见案例介绍模块并浏览',
                   open:true,
                   show:true,
                   type:'copyWriting'
               }
            ]
        }
    },
    watch:{
        shareObj(val){
            if(val&&val.app_id){
                if(!val.h5&&!val.micro_program&&!val.content_url){
                    // this.$router.push({
                    //     path:'/casePoster?appid='+val.app_id
                    // })
                }else{
                    this.module[0]['show'] = val.h5;
                    this.module[1]['show'] = val.micro_program;
                    this.module[2]['show'] = val.content_url;
                }
                console.log('moudle,',this.module)
            }
        }

    },
    methods:{
        copyWord(){
            console.log('ewqeq')
    　　　  var clipboard = new this.Clipboard(".copyPhone"); //在main.js中引用
            clipboard.on("success", e => {
                // 释放内存
                this.$emit('cancle');
                setTimeout(() => {
                    this.$Toast('复制成功')
                    clipboard.destroy();
                }, 1);
                
            });
            clipboard.on("error", e => {
                // 不支持复制
                this.$Toast({
                message: "该浏览器不支持自动复制",
                type: "warning"
                });
                // 释放内存
                clipboard.destroy();
            });
        },
        cancle(){
            this.$emit('cancle')
        },
        next(){ //选择分享
            let module = this.module;
            console.log('module',module)
            let query = `appid=${this.shareObj.app_id}`
            module.forEach(item=>{
                if(item.show&&item.open){
                    query+=`&${item.type}=0`
                }else{
                    query+=`&${item.type}=1`
                }
            })
            // this.share(this.shareObj,query);
            this.$emit('cancle')
            this.$router.push({
                path:'/casePoster?'+query
            })
            
        },
       
    }
}
</script>
<style lang="scss" scoped>
.popup{
    width:100%;
    border-radius: 10px 10px 0 0;
    .dialog{
        width:100%;
        background: #f5f5f5;
        border-radius: 10px 10px 0 0;
        overflow:hidden;
        .moduleHeader{
            padding:.24rem .32rem;
            position: relative;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
            border-bottom:1px solid #ccc;
            .moduleBox{
               
            }
            .title{
                font-size: .32rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #323233;
            }
            .type{
                font-size: .28rem;
                font-weight: 400;
                color: #323233;
                font-family: PingFangSC-Medium, PingFang SC;
            }
            .sub{
                font-weight: 500;
                color: #999;
                font-size:.24rem;
                margin-top:.08rem;
            }
            i{
                position: absolute;
                top:.32rem;
                right:.32rem;
                font-size:.32rem;
            }
            .switchBtn{
                position: absolute;
                top:.38rem;
                right:.32rem;
            }
        }
        .other{
            padding:.32rem;
        }
        .next{
            margin:1.32rem .32rem .5rem;
            // padding:0 .32rem 0 .32rem;
            height: .8rem;
            background: #105CFB;
            border-radius: .4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size:.28rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color:#fff;
        }
        // .call{
        //     display: block;
        //     text-decoration: none;
        //     padding:13px 0 15px;
        //     text-align: center;
        //     color:#333333;
        //     font-family: PingFangSC-Regular, PingFang SC;
        //     box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.08);
        //     border-bottom:1px solid #f5f5f5;
        //     background: #fff;
        // }
        // .cancle{
        //     margin-top:10px;
        //     padding:13px 0 15px;
        //     text-align: center;
        //     color:#333333;
        //     font-family: PingFangSC-Regular, PingFang SC;
        //     background: #fff;
        // }
    }
    /deep/  .mint-switch-input:checked+.mint-switch-core{
        border-color: #1472FF;
        background-color: #1472FF;
    }
}
    
</style>