<template>
    <div class="detail">
        <div class="detailBox">
        <!-- <div class="colorful"></div>
        <div class="saleInfo">
            <div class="infoLeft">
                <div class="avator"></div>
                <div class="info">
                    <p class="name">销售名字</p>
                    <p class="position">职位</p>
                </div>
                <div style="clear:both"></div>
            </div>
            <div class="infoRight">
                <div class="phone">
                    <i class="iconfont icon-wechat"></i>
                    加微信
                </div>
                 <div class="phone">
                    <i class="iconfont icon-gengduobeifen25"></i>
                    大电话
                </div>
            </div>
        </div> -->
        <div class="tips">访问店铺</div>
        <div class="list">
            <case-list  :all_cases="all_cases" page="detail" @postMicro="postMicro" @share="share"></case-list>
        </div>
        <template v-if="all_cases[0].content">
            <div class="tips">案例详情</div>
            <div class="intro">
                <div class="content" v-html="all_cases[0].content">
                </div>
            </div>
        </template>
       
        <!-- <pop-dialog @cancle="popupVisible = false"
         :popupVisible="popupVisible" @next="nextGo" :shareObj="all_cases[0]"></pop-dialog> -->
         <van-dialog :vanDialogFlag.sync="vanDialogFlag" :imgUrl="all_cases[0].micro_program_url"></van-dialog>
      </div>
       <div class="companySign">
             <p class="sign"><img src="@/assets/logo_bg.png" alt="">小鹅通提供技术支持</p>
             <p class="sign">www.xiaoe-tech.com</p>
         </div>
    </div>
</template>
<script>
import caseList from '../components/caseList'
import popDialog from '../components/popDialog'
import vanDialog from '../components/vantDialog'
import pdf from 'vue-pdf'
import { getToken } from '../../../util/util'
export default {
    name:'detail',
    components:{
        caseList,popDialog,vanDialog,pdf
    },
    data(){
        return{
            popupVisible:false,
            // url:'https://wechatapppro-1252524126.file.myqcloud.com/appTCVlUyvG2205/documentation/b_u_5e537e852a54a_zuMuCg42/kmzq2whm0dpc.pdf'
            url:'http://wechatapppro-1252524126.file.myqcloud.com/apprnDA0ZDw4581/image/2955219be0776fd4d97cd77c8a694c47.pdf',
            all_cases:[
                {
           
                }
            ],
            app_id:'',
            ids:{},
            query:{},
            vanDialogFlag:false,
        }
    },
    created(){
        // this


    },
    mounted(){
        let app_id = this.$route.query.appid;
        this.query = this.$route.query;
        
        if(app_id){
            this.app_id = app_id;
            this.getDetail();
        }
        console.log('this.all_cases[0].shop_name',this.all_cases[0].shop_name)
        let userInfo = getToken('userInfo');
        let saleInfo = getToken('saleInfo');
        if(userInfo&&saleInfo){
             this.ids = {
                user_id:userInfo.user_id,
                sale_id:saleInfo.user_id,
            }
        }
        // try {
        //     sensors.track('view_page', {
        //         page_id: 'C#sales_assistant#user#case_base#case_detail',
        //         l_program: '销售助手',
        //         page_name: '落地页',
        //         page_path: 'C端#销售助手#用户端#案例库#案例详情页面',
        //         share_user_id:saleInfo.user_id,
        //         union_id:userInfo.user_id,
        //         share_type:2, 
        //         course_id:this.all_cases[0].shop_name||'赛凡国际空中课堂',
        //         cmm1:userInfo.user_name,
        //         cmm2:saleInfo.bind_user_remark,
        //     })
        // } catch(err) {
        //     console.log(err);
        // }
    },
    methods:{
        star(){
            let params = {
                app_id:this.all_cases[0].app_id,
                action:this.all_cases[0].collect_state == 1?4:3,
            }
            let text = this.all_cases[0].collect_state == 1?'取消':''
            this.$http.post('/user_service/case_library/report_action',params).then(res=>{
                console.log('res',res)
                if(res.code == 0){
                    this.$Toast(text+'收藏成功');
                    this.getDetail();
                }
            })

        },
        share(){
            this.popupVisible = true;
        },
        postMicro(){
            this.vanDialogFlag = true;
        },
        getDetail(){
            let params = {
                app_id:this.app_id
            }
            this.$http.get('/user_service/case_library/get_case_details',{params})
            .then(res=>{
                
                if(res.code == 0){
                    this.all_cases = [];
                    this.all_cases.push(res.data);
                    console.log('res',this.all_cases)
                    // document.title = res.data.case_name?res.data.case_name:'案例详情'
                    // this.shareContent(res.data)
                }
            })
        },
        shareContent(item){
            let origin = window.origin;
            let obj = {
                title:item.case_name?item.case_name:item.shop_name?item.shop_name:'案例标题',
                desc:'点击查看案例详情',
                link:origin+'/subapp/sub_users/detail?appid='+item.app_id,
                imgUrl:item.cover_url?item.cover_url:item.shop_logo?item.shop_logo:this.defaultImg,
                success:function(){
                    // alert('成功')
                }
            }
            // this.$EventBus.$emit('shareContent',true,obj);
        },
        loader(){
            console.log('渲染完成');
        },
        sharePage(){
            this.popupVisible = true;
        },
        nextGo(){
             this.popupVisible = false;

            
        }
    }
}
</script>
<style lang="scss">
.detail{
    width:100%;
    position: relative;
    // padding-top:1px;
    min-height:100vh;
    background: #f5f5f5;
    .detailBox{
        width:100%;
        padding-bottom:1.8rem;
        position: relative;
   
    .colorful{
        width: 100%;
        height: 3rem;
        position: absolute;
        top:0;
        left:0;
        z-index: 1;
        background: linear-gradient(134deg, #82CDFF 0%, #AD66FF 100%);
    }
    .saleInfo{
        margin:.32rem .32rem .24rem .32rem;
        background: #fff;
        border-radius: .16rem;
        padding:.16rem .12rem;
        position: relative;
        z-index: 3;
        display: flex;
        .infoLeft{
            flex:1;
            .avator{
                width:.8rem;
                height:.8rem;
                background: red;
                border-radius: 50%;
                float: left;
                // display: inline-block;
            }
            .info{
                float: left;
                margin-left:.16rem;
                color:#999999;
                font-size:.24rem;
                .name{
                    font-size: .28rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #323233;
                    margin-bottom:.04rem;
                }
                // display: inline-block;
            }
            
        }
        .infoRight{
            width:2.16rem;
            .phone{
                font-size: .24rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                text-align: center;
                display: inline-block;
                margin-left:.24rem;
                i{
                    font-size:.4rem;
                    display: block;
                    color:#666666;
                    margin-bottom:.08rem;
                }
            }
        }
    }
    .tips{
        padding:.24rem 0 .0 ;
        width:calc(100% - .48rem);
        margin-left:.24rem;
        font-size: .32rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
    .list{
        padding-top:1px;
    }
    .intro{
        padding:.24rem 0 .24rem 0 ;
        width:calc(100% - .48rem);
        // background: #fff;
        // box-shadow: 0px .04rem .4rem 0px rgba(0, 0, 0, 0.08);
        border-radius: .16rem;
        margin-left:.24rem;
        .title{
            font-size:.32rem;
            font-weight: 500;
            margin:0 0 .32rem 0;
            color:#333333;
        }
        .content{
            background: #FFFFFF;
            box-shadow: 0px .04rem .4rem 0px rgba(0, 0, 0, 0.08);
            border-radius: .16rem;
            min-height:5rem;
            position: relative;
            padding: .32rem;
            word-break: break-all;
            
            img{
                width:100%;
            }
            
            .share{
                position: fixed;
                top:10rem;
                right:.4rem;
                z-index: 4;
            }
            .star{
                position: fixed;
                top:11rem;
                right:.4rem;
                z-index: 4;
            }
            .active{
                color:rgb(255,204,0);
            }
        }
    }
    .fillHeight{
        width:100%;
    }
    
    }
    .companySign{
        width:100%;
        // margin-top:1.32rem;
        // padding-bottom:.32rem;
        position: absolute;
        bottom:.32rem;
        left:0;
        .sign{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top:.1rem;
            font-size:.24rem;
            color: #C8C8C8;
            img{
                width:.56rem;
                height:.56rem;
                margin-right:.1rem;
            }
        }
    }

}
    
</style>