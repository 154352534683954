<template>
    <van-popup v-model="vanDialogFlag" class="dialog" :closeOnClickOverlay="false">
          <div class="box">
              <div class="header">小程序码 <i class="iconfont icon-xiaohaoshanchu" @click="cancle"></i></div>
              <div class="code"><img :src="imgUrl" alt=""></div>
              <div class="tips">长按识别</div>
          </div>

    </van-popup>
</template>
<script>
export default {
    name:'vantDialog',
    props:{
        vanDialogFlag:{
            type:Boolean
        },
        imgUrl:{
            type:String
        }
    },
    methods:{
        cancle(){
            this.$emit('update:vanDialogFlag',false)
        }

    },
}
</script>
<style lang="scss" scoped>
.dialog{
    display: flex;
    align-items: center;
    justify-content: center;
}
.box{
    width:5.76rem;
    height:4.92rem;
    background: #fff;
    .header{
        font-size: .32rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
        padding:.48rem 0 .32rem;
        position: relative;
        i{
            position: absolute;
            top:.24rem;
            right:.24rem;
            font-size:.32rem;

        }
    }
    .code{
        width:2.4rem;
        height:2.4rem;
        margin: 0 auto;
        img{
            width:100%;
        }
    }
    .tips{
        text-align: center;
        font-size: .32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top:.32rem;
    }
}
    
</style>